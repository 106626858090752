import Navbar from './nav/Navbar';
import "./App.css"
import Form from './surveyform/GetForm';
// import Product2 from "./cat2/Product2";


function App() {

  return (
    <div>
        <Navbar/>
        <Form />
        {/* <Product2/> */}
        

    </div>
  
    
  );
}

export default App;
