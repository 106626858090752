import React from 'react';
import SurveyForm from './SurveyForm';
import './Survey.css'


function Form() {
  return (
    <div style={{ margin: '20px' }}>
       <div>
      <SurveyForm />
    </div>
    </div>
   
  );
}

export default Form;
