
//new code
import React from 'react';
import styles from './Nav.module.css'; // Import styles as a module
import Kotak from '../Productimg/klogo.png'; // Adjust the path based on your project structure

function TextLinkExample() {
  return (
    <div className={styles.topnav} id="myTopnav">
      <div className={styles.Kotak}>
        <img className={styles.klogo} src={Kotak} alt="Kotak Logo" />
        <span className={styles.kname}>Kotak Mahindra Bank</span>
      </div>
      <div className={styles.Karnival}>
        <span className={styles.kname}>का&nbsp;Rnival</span>
      </div>
    </div>
  );
}

export default TextLinkExample;

