import React, { useState } from 'react';
import './Survey.css'
import Singlepost from '../cat2/Product2'; 
import Life from "../Productimg/klihi.jpg"
import Mf from "../Productimg/kmf.png";
import loan from "../Productimg/kloan.jpg";
import save from "../Productimg/ksa.png";
import fr from "../Productimg/kfa.jpg";
import CC from "../Productimg/ccard.jpeg";
import acc from "../Productimg/acc.jpg";
import accc from "../Productimg/accc.jpg";
import Alo from "../Productimg/Alo.jpg";
import lapi from "../Productimg/lapii.jpg";
import prosi from "../Productimg/prosi.jpg";
import fori from "../Productimg/forexc.jpg";
import lassi from "../Productimg/lassi.jpg";




const SurveyForm = () => {
  const Mproduct = {
    Everyday: {
      title: "Everyday",
      content: "A one-of-its-kind Savings Account.",
      link: "https://www.kotak.com/en/personal-banking/accounts/savings-account/everyday-saving-account.html",
      image: save,
      
    },

    MF:{
      title: "Mutual fund",
      content: "Our recommended mutual funds guide.",
      link: " https://www.kotak.com/en/personal-banking/investments/mutual-funds.html",
      image: Mf,


    },

    Ins: {
      title: "Insurance",
      content: "Tailor-made insurance policies.",
      link: "https://www.kotaklife.com/",
      image: Life,
    },
    AL: {
      title: "Car",
      content: "Get closer to your dream car.",
      link: "https://www.kotak.com/en/personal-banking/loans/car-loan.html ",
      image: Alo,
    },
    PL: {
      title: "Personal Loan",
      content: "Quick loan sanction, part prepayment available.",
      link: " https://www.kotak.com/en/personal-banking/loans.html",
      image: loan,
    },
    credit: {
      title: "Credit Cards",
      content: "Cashless and rewarding world of Credit Cards.",
      link: "https://www.kotak.com/en/personal-banking/cards/credit-cards.html ",
      image: CC ,

    },
    Pro: {
      title: "Pro Savings",
      content: "Fuel Surcharge Waiver, Complimentary Insurance.",
      link: "https://www.kotak.com/en/personal-banking/accounts/savings-account/pro-savings-account.html",
      image: prosi,

    },
    Aces: {
      title: "Ace Savings",
      content: "Feature-packed savings account for an unmatched banking experience.",
      link: "https://www.kotak.com/en/personal-banking/accounts/savings-account/ace-savings-account.html ",
      image: acc,

    },
    Acec: {
      title: "ACE Current ",
      content: "Efficient banking for larger businesses.",
      link: " https://www.kotak.com/en/business/accounts/current-accounts/ace.html",
      image: acc,

    },
    forex: {
      title: "Forex Card",
      content: "Contactless travel with Kotak Forex Card.",
      link: "https://www.kotak.com/en/personal-banking/cards/prepaid-card/forex-card.html ",
      image: fori ,

    },
    FD: {
      title: "FD/RD",
      content: "Higher Fixed Deposit rates available.",
      link: "https://www.kotak.com/en/personal-banking/deposits/fixed-deposit.html ",
      image: fr,
    },
    astra05: {
      title: "Astra 05 Current ",
      content: "Tailored for large enterprises with generous features.",
      link: "https://www.kotak.com/en/business/accounts/current-accounts/astra-05.html ",
      image: accc,

    },
    astra15: {
      title: "Astra 15 Current ",
      content: "Ideal for larger enterprises with comprehensive features.",
      link: "https://www.kotak.com/en/business/accounts/current-accounts/astra-15.html ",
      image: accc ,

    },
    proc: {
      title: "Pro Current ",
      content: "Designed for professionals managing small to medium businesses.",
      link: "https://kotak.com/en/business/accounts/current-accounts/pro/features.html ",
      image:prosi ,

    },
    lap: {
      title: "Loan against Property",
      content: "Fulfill personal and business needs with our Loan against Property.",
      link: " https://www.kotak.com/en/business/loans/loan-against-property.html",
      image: lapi,

    },
    las: {
      title: "Loan Against Securities ",
      content: "Access funds tied up in securities.",
      link: "https://www.kotak.com/en/personal-banking/loans/loans-against-securities.html ",
      image: lassi,

    },
    lrd: {
      title: "LRD ",
      content: "Loan against rent receipts for property owners.",
      link: " https://www.kotak.com/en/stories-in-focus/loans/home-loan/lease-rental-discounting-lrd-meaning-benefits.html",
      image: lapi,

    },
  }

const [selectedProducts, setSelectedProducts] = useState([]);

const [formSubmitted, setFormSubmitted] = useState(false);

const catA = ["Everyday", "Ins","AL","PL", "MF", "credit"];
const catA1 = ["proc","Ins","AL","PL", "MF", "credit card"];
const catB = ["Aces", "Ins","FD", "AL", " gold","forex", "PL", "MF", "credit card"];
const catB1 = ["proc","astra05", "Ins","FD", "AL", " gold","forex", "PL", "MF", "credit card"];
const catC =["Aces","FD","Ins","lap", "las", "lrd", "AL", " gold","forex", "PL", "MF", "credit card"  ];
const catC1 =["Acec","astra05", "astra15","FD","lap", "las", "lrd", "AL", " gold","forex", "PL", "MF", "credit card"  ];
const catD = ["forex","MF", "gold", "FD"];


  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    income: '',
    occupation: '',
    Maritial: '',
      
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
    //new correction
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(formData);
    
      let matchedCategories = [];


      

    // new logic

    if(formData.income === "low"){
      if(formData.age === "18-30" || formData.age === "30-45" || formData.age === "45-60"){
        if(formData.occupation === "Business"){
          matchedCategories = catA1.filter(category => Mproduct[category]);
        }else{
          matchedCategories = catA.filter(category => Mproduct[category]);
        }
      }else{
        matchedCategories = catD.filter(category => Mproduct[category]);
      }
    }else if(formData.income === "medium"){
        if(formData.age === "18-30" || formData.age === "30-45" || formData.age === "45-60"){
          if(formData.occupation === "Business"){
            matchedCategories = catB1.filter(category => Mproduct[category]);
          }else{
            matchedCategories = catB.filter(category => Mproduct[category]);
          }
        }else{
          matchedCategories = catD.filter(category => Mproduct[category]);
        }
    }else if(formData.income === "high"){
      if(formData.age === "18-30" || formData.age === "30-45" || formData.age === "45-60"){
        if(formData.occupation === "Business"){
          matchedCategories = catC1.filter(category => Mproduct[category]);
        }else{
          matchedCategories = catC.filter(category => Mproduct[category]);
        }
      }else{
        matchedCategories = catD.filter(category => Mproduct[category]);
      }
  }



      if (matchedCategories.length > 0) {
        const matchedProducts = matchedCategories.map(category => Mproduct[category]);
        setSelectedProducts(matchedProducts);
      } else {
        setSelectedProducts([]);
      }

      setFormSubmitted(true); 

    
  };

  return (
    <>
     {formSubmitted ? (
        <Singlepost products={selectedProducts} />
      ) : (
       
    <form className='App' onSubmit={handleSubmit}>
      <h2>Survey Form</h2>
      <div className="form-group">
        <label htmlFor="age">Age:</label>
        <select
          id="age"
          name="age"
          value={formData.age}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Age</option>
          <option value="18-30">18-30</option>
          <option value="30-45">30-45</option>
          <option value="45-60">45-60</option>
          <option value="61">Above 60</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="gender">Gender:</label>
        <select
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="income">Income:</label>
        <select
          id="income"
          name="income"
          value={formData.income}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Income</option>
          <option value="low">25k&nbsp;-&nbsp;50k</option>
          <option value="medium">50k&nbsp;-&nbsp;2L</option>
          <option value="high">Above 2L</option>
        </select>
      </div>


      <div className="form-group">
        <label htmlFor="occupation">Occupation:</label>
        <select
          id="occupation"
          name="occupation"
          value={formData.occupation}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Occupation</option>
          <option value="Business">Business</option>
          <option value="Salaried">Salaried</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="Maritial">Maritial Status:</label>
        <select
          id="Maritial"
          name="Maritial"
          value={formData.Maritial}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Maritial Status</option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
        </select>
      </div>


      <button type="submit">Submit</button>
    </form>


      )}
      
    </>
  );
};

export default SurveyForm;


