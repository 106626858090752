import './Product2.css';


const Singlepost = ({products=[]}) => {
    console.log(products);
    if (!Array.isArray(products)) {
        return null; // or handle the case where products is not an array
      }

      const handleReload = () => {
        window.location.reload();
      };
    return (
      <>
      <h2 className="recommended-heading">Recommended Products</h2>
      <button onClick={handleReload}>Go Back</button>
      <div className='rp'> 
        <div className="grid">

                {/*Dynamic product*/}
           
                    {/* runing good Dynamic product*/}
                    {products.map((product, index) => (
        <div className="grid__item" key={index}>
          <div className="card">
            <img className='pimg' src={product.image} alt={product.title} />
            <div className="card__content">
              <h1 className="card__header">{product.title}</h1>
              <p className="card__text">{product.content}</p>
              <a className="card__btn" href={product.link} target="_blank" rel="noopener noreferrer">
                Explore <span>&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      ))}
        </div>
        </div>
        </>
    );
};

export default Singlepost;

